import React from 'react'
import cx from 'classnames';
import Helmet from 'react-helmet';

const CustomHelmet = props => {

return(
   <Helmet title={props.title}>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <link rel="preconnect" href="https://sumo.com"></link>
      <link rel="preconnect" href="https://sumo.b-cdn.net"></link>
      <link rel="preconnect" href="https://load.sumo.com"></link>
      <link rel="preconnect" href="https://secure.livechatinc.com"></link>
      <link rel="preconnect" href="https://script.hotjar.com"></link>
      <link rel="preconnect" href="https://vars.hotjar.com"></link>
      <link rel="preconnect" href="https://api.chat.io"></link>
      <link rel="preconnect" href="https://www.google-analytics.com"></link>
      <link rel="preconnect" href="https://analytics.twitter.com"></link>
      <link rel="preconnect" href="https://static.hotjar.com"></link>
      <link rel="preconnect" href="https://embedsocial.com"></link>
      <link rel="preconnect" href="https://connect.facebook.net"></link>
      <link rel="preconnect" href="https://cdn.chatio-static.com"></link>
      {props.children}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@hashching" />
      <meta name="twitter:domain" content="HashChing" />
      <meta name="twitter:creator" content="@hashching" />
      <meta property="og:type" content="website" />
     <meta property="og:locale" content="en_US" />     
    </Helmet>
);
}
export default CustomHelmet;